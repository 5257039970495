@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}

body {
  overflow: hidden;
  position: fixed;
  height: 100%;
}

::selection {
  background: rgba(26, 188, 156, 0.3);
}

.container {
  max-width: 100%;
}

.main-page {
  height: 100vh;
  width: 100vw;
}

.logo {
  max-width: 100%;
  display: flex;
  width: 350px;
  margin: auto;
}

.wrapper {
  width: 100%;
  background: #fff;
}

.wrapper form .row {
  height: 56px;
  margin-bottom: 15px;
  position: relative;
}

.wrapper form .row input {
  height: 100%;
  width: 100%;
  outline: none;
  padding-left: 20px;
  border-radius: 5px;
  border: 1px solid lightgrey;
  font-size: 16px;
  transition: all 0.3s ease;
}

form .row input:focus {
  border-color: #16a085;
  box-shadow: inset 0px 0px 2px 2px rgba(26, 188, 156, 0.25);
}

form .row input::placeholder {
  color: #999;
}

.wrapper form .row i {
  position: absolute;
  width: 47px;
  height: 100%;
  color: #fff;
  font-size: 18px;
  background: #16a085;
  border: 1px solid #16a085;
  border-radius: 5px 0 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapper form .pass {
  margin: 4px 4px 23px 4px;
}

.wrapper form .pass a {
  color: #16a085;
  font-size: 17px;
  text-decoration: none;
}

.wrapper form .pass a:hover {
  text-decoration: underline;
}

.wrapper form .button input {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  padding-left: 0px;
  background: #242526;
  border: 1px solid #141414;
  cursor: pointer;
}

form .button input:hover {
  background: #090909;
}

.errorMessage {
  color: red;
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
}

.wrapper form .signup-link {
  text-align: center;
  margin-top: 25px;
  padding-right: 20px;
}

.wrapper form .signup-link a {
  color: #16a085;
  text-decoration: none;
}

form .signup-link a:hover {
  text-decoration: underline;
}

.button-container {
  display: flex;
  margin-top: 20px;
  width: 93%;
  margin-left: 7%;
  margin-right: 7%;
  margin-bottom: 20px;
}

.button-1 {
  width: 50%;
}

.button-2 {
  width: 50%;
}

.carwash-button:disabled {
  cursor: not-allowed; /* Change cursor to indicate disabled state */
}

.carwash-button:disabled:hover {
  cursor: not-allowed; /* Change cursor to indicate disabled state */
}

.log-text {
  font-size: 18px;
  padding-left: 7%;
  color: #000;
}

/* Custom scrollbar styles */
.log-box::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
}

.log-box::-webkit-scrollbar-track {
  background: #ffffff; /* Background of the scrollbar track */
}

.log-box::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Roundness of the scrollbar thumb */
  border: 3px solid #f1f1f1; /* Padding around the scrollbar thumb */
}

.log-box::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Color of the scrollbar thumb on hover */
  
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 3vh;
  top: 3vh;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #242526;
  border-radius: 5px;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #090909;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
  margin: 1vh;
  left: 1vh;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #000;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  max-width: 60vw;
}

/* General sidebar styles */
.bm-menu {
  background: #ffffff;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  text-align: right;
}

/* Individual item */
.bm-item {
  display: inline-block;
  color: #000;
  text-decoration: none;
  margin-bottom: 10px;
}

.menu-item-logout {
  position: fixed;
  color: #000;
  text-decoration: none;
  bottom: 2.5em;
  right: 1.5em;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.status-text {
  font-size: 12px;
  color: #000;
}

/* Modal container */
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 80vw;
  max-height: 30vh;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  z-index: 1050; /* Ensure the modal is above other elements */;
  padding: 15px 30px;
}

/* Modal header */
.modal h2 {
  margin-top: 10px;
  font-size: 18px;
  font-weight: bold;
}

/* Modal body */
.modal div {
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 16px;
}

/* Modal buttons */
.modal-button-container {
  display: flex;
  justify-content: flex-end;
}

/* Cancel button */
.cancel-button {
  background-color: lightgrey;
  border-width: 0;
  color: #000;
  padding: 12px 25px;
  border-radius: 5px;
  margin-right: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cancel-button:hover {
  background-color: #e2e6ea;
}

/* Confirm button */
.confirm-button {
  background-color: #242526;
  color: #fff;
  border-color: #000000;
  padding: 12px 25px;
  border-radius: 5px;
  border-width: 0;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.confirm-button:hover {
  background-color: #090909;
}

/* Modal backdrop */
.ReactModal__Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  z-index: 1040; /* Ensure the backdrop is below the modal but above other elements */
  transition: opacity 2000ms ease-in-out;
}

.pricing-container {
  display: flex;
  padding-left: 7%;
  padding-right: 7%;
  justify-content: space-between;
  gap: 5%;
  margin-bottom: 20px;
  margin-top: 15px;
}

.pricing-inputs input[type=Number]{
  width: 100%;
  padding: 6px 12px;
  margin: 5px 0px;
}

.pricing-inputs button {
  background-color: #242526;
  width: 100%;
  color: #fff;
  padding: 12px 12px;
  border-radius: 5px;
  border-width: 0;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pricing-inputs button:hover {
  background-color: #090909;
}

.pricing-text {
  font-size: 12px;
}

.pricing-inputs button:disabled {
  background-color: lightgrey;
  cursor: not-allowed;
  border-width: 0;
  pointer-events: none; /* Prevent any pointer events */
  color: #000
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

@media screen and (max-width: 768px) {
  .login-outer-container {
    margin: 5vh auto;
    height: 100vh;
    width: 100vw;
  }
  .outer-container {
    display: flex;
    height: 100vh;
    width: 100vw;
  }
  .container {
    margin: 8vh auto;
    width: 100vw;
  }

  .wrapper form .button {
    position: relative;
    bottom: 0;
    width: 100%;
    margin-top: 20px;
  }

  .wrapper form {
    padding-left: 7%;
    padding-right: 7%;
  }

  .carwash-button {
    color: #000;
    font-size: 15px;
    padding: 25px 25px;
    font-weight: 500;
    cursor: pointer;
    border-radius: 5px;
    border-width: 0px;
    margin-bottom: 5px;
    transition: background-color 0.3s ease;
    width: 86%;
  }

  .log-box {
    padding: 0px 10px;
    border: 1px solid #ccc;
    height: 35vh;
    overflow-y: auto;
    margin-top: 5px;
    font-size: 12px;
    width: 86%;
    margin: 5px auto;
  }
}

@media (min-width: 768px) {
  .outer-container {
    display: flex;
    height: 100vh;
    width: 100vw;
  }
  .login-outer-container {
    display: flex;
    height: 100vh;
    width: 100vw;
  }
  .container {
    margin: auto;
    padding: 0 20px;
    width: 400px;
  }

  .carwash-button:hover {
    background-color: #6fe3bc;
  }

  .carwash-button {
    color: #000;
    font-size: 15px;
    padding: 15px 25px;
    font-weight: 500;
    cursor: pointer;
    border-radius: 5px;
    border-width: 0px;
    margin-bottom: 5px;
    transition: background-color 0.3s ease;
    width: 86%;
  }

  body {
    background: #F2EFEF;
  }

  .wrapper {
    border-radius: 5px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 0 0 2vh 0;
  }

  .wrapper form {
    padding: 10px 25px 34px 25px;
  }

  .log-box {
    padding: 0px 10px;
    border: 1px solid #ccc;
    height: 100px;
    overflow-y: auto;
    margin-top: 5px;
    font-size: 12px;
    width: 86%;
    margin: 10px auto;
  }
}
